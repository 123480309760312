import "../jquery-ui";
import 'bootstrap';

function floatingProduct()  {
  $("#product-dropdown").dropdown();
  $("#account-dropdown").dropdown();
  $("#locale-dropdown").dropdown();

  $("#product-dropdown").on('mouseenter', function () {
    if (!$(".product-menu").hasClass('show')) $(this).dropdown('toggle');
  })

  $("#product-dropdown").on('mouseleave', function () {
    setTimeout(() => {
      if (!$('.product-menu:hover').length && !$('#product-dropdown:hover').length) $("#product-dropdown").dropdown('toggle');
    }, 100)
  })

  $(".product-menu").on('mouseleave', function(){
    if($(".product-menu").hasClass('show')) $(this).dropdown('toggle');
  })
}


function createNotificationPopover() {
  const locale = $("body").data('locale')
  // =========================================================
  // Notification popover
  function popoverContent() {
    $.ajax({
      dataType: 'json',
      url: `/${locale}/notifications_summary`,
      method: "GET",
      success: function (data) {
        $('.notification-popover-body').html(data.html);
      }
    });
  }
   
  $('#notification-toggle-popover').popover({
    template: `
    <div class="notification-popover" role="tooltip">
      <div class="notification-popover-arrow"></div>
      <div class="popover-body notification-popover-body"></div>
    </div>`,
    html: true,
    content: function(){
      popoverContent();
      return '<div class="notification-loading"><div class="notification-loading__logo"></div></div>';
    }
  })
  // =========================================================
}

floatingProduct();
createNotificationPopover();